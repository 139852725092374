import type { AuthReadyEventDetail, TV2User } from '../global';
import { setupClient } from './client';
import { trackUserLoginEvent } from './reporting';
import { hasRecentlyCheckedLogin, setRecentlyCheckedLoginCookie } from './cookie';
import { handleAuthCallback } from './callback';

export const handleLogin = () => {
  setupClient()
    .then(handleAuthCallback)
    .then(async auth0Client => {
      /*
       * This call to getTokenSilently will identify whether there is a valid auth0 session.
       * E.g. if the user has logged in on another subdomain, this will authenticate the
       * user on the current subdomain as well.
       *
       * The tv2HasCheckedLogin cookie is used to avoid calling getTokenSilently on every page load.
       */
      if (!hasRecentlyCheckedLogin()) {
        try {
          await auth0Client.getTokenSilently({
            authorizationParams: { redirect_uri: location.origin },
          });
        } catch (_e) {
          // this is expected when there is no valid auth0 session and can be ignored
        } finally {
          setRecentlyCheckedLoginCookie();
        }
      }

      const user = await window?.TV2?.getLoggedInUser();
      const userId = user?.id;

      updateUI(user);

      trackUserLoginEvent('User Authenticated', userId);

      // Deprecated - still in use until all uses are updated to new event
      document.dispatchEvent(
        new CustomEvent('tv2-menu:auth0-ready', {
          detail: {
            app: false,
            isReady: true,
            userId,
          } as AuthReadyEventDetail,
        }),
      );

      // New event to take over for above event
      document.dispatchEvent(
        new CustomEvent('tv2-menu:auth-ready', {
          detail: {
            app: false,
            isReady: true,
            userId,
          } as AuthReadyEventDetail,
        }),
      );
    })
    .catch(e => {
      console.error('TV 2 Menu: Auth:', e.message);
    });
};

const updateUI = (user?: TV2User) => {
  const $authBubble = document.getElementById('tv2-menu-auth-bubble');
  const $authLabel = document.getElementById('tv2-menu-auth-label');
  const $authLabelSecondary = document.getElementById('tv2-menu-auth-label--secondary');
  const $loginButton = document.getElementById('tv2-menu-login-btn');
  const $logoutButton = document.getElementById('tv2-menu-logout-btn');

  if ($loginButton) {
    $loginButton.onclick = async function () {
      if (user) {
        const $userProfileContextMenu = document.getElementById('tv2-menu-auth-context-menu');
        $userProfileContextMenu?.classList.toggle('tv2-menu__auth-menu--open');
      } else {
        await window.TV2?.login('Navbar', window.lab_metadata?.tags?.sectionTag);
      }
    };
  }

  if ($logoutButton) {
    $logoutButton.onclick = window.TV2?.logout;
  }

  if ($authBubble && $authLabel) {
    if (user) {
      $authBubble.classList.add('tv2-menu__auth-bubble--authenticated');
      $authBubble.innerText = user?.firstname?.[0] ?? '';
      $authLabel.innerText = user?.firstname?.split(' ')?.[0] ?? '';
      if ($authLabelSecondary) {
        $authLabelSecondary.style.display = 'none';
      }
    } else {
      $authLabel.innerText = 'Logg inn';
      $authBubble.classList.remove('tv2-menu__auth-bubble--authenticated');
    }
  }
};
