import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../common/localStorage';
import type { AuthReadyEventDetail } from '../global';

// TODO: Remove when all apps are exposing native getToken()
export const setTokenToLocalStorage = (token: string) => {
  const existingLocalStorageToken = getLocalStorageItem('appAuthToken');

  if (token === 'null' || token === '') {
    removeLocalStorageItem('appAuthToken');
    removeLocalStorageItem('appUser');
  } else if ((token && !existingLocalStorageToken) || token !== existingLocalStorageToken) {
    setLocalStorageItem('appAuthToken', token);
  }
};

/**
 * To prevent double login in app, we store the user and token in localstorage
 * This is currently only functional in Sportenapp, so the TV2no-app does use webs
 * Auth0 login for now until we get
 *  a) Support for both JSBridge and webview.messageHandlers in TV2no-app
 *    or
 *  b) Auth0 on the server that clients can share client session
 */
export const handleAppLogin = () => {
  const { userApiUrl } = window.AUTH0_CONFIG;

  const login = async function (reasonToLoginCategory?: string, reasonToLoginName?: string) {
    const data = { reasonToLoginCategory, reasonToLoginName };

    if (window?.webkit?.messageHandlers?.onLoginEvent) {
      window.webkit.messageHandlers.onLoginEvent.postMessage(JSON.stringify(data));
    }

    if (typeof window.TV2JSBridge !== 'undefined' && typeof window?.TV2JSBridge?.onLoginEvent === 'function') {
      window.TV2JSBridge.onLoginEvent(JSON.stringify(data));
    }
  };

  const getLoggedInUser = async function () {
    try {
      const token = await getToken();

      const appUser = getLocalStorageItem('appUser');

      if (appUser) {
        return appUser;
      }

      const userResponse = await fetch(userApiUrl, {
        method: 'get',
        credentials: 'include',
        headers: new Headers({
          Authorization: 'Bearer ' + token,
        }),
      });

      const user = await userResponse.json();

      setLocalStorageItem('appUser', user, 6);

      return user;
    } catch (e) {
      return;
    }
  };

  // TODO: Remove when all apps are exposing native getToken()
  const setToken = function (token: string) {
    console.warn('DEPRECATION WARNING | TV 2 Menu: SetToken: ', token?.length);

    setTokenToLocalStorage(token);

    document.dispatchEvent(
      new CustomEvent('tv2-menu:set-token', {
        detail: {
          isSet: true,
          app: true,
          deprecated: true,
        },
      }),
    );

    // IOS webview demands that a string is returned
    return 'TOKEN_SET';
  };

  const getToken = async function () {
    // IOS
    if (window?.webkit?.messageHandlers?.getToken) {
      return await window.webkit.messageHandlers.getToken.postMessage('getToken');
    }

    // Android
    if (typeof window.TV2JSBridge !== 'undefined' && typeof window.TV2JSBridge?.getToken === 'function') {
      return await window.TV2JSBridge.getToken();
    }

    // TODO: Remove when all apps are exposing native getToken()
    return getLocalStorageItem('appAuthToken') ?? '';
  };

  const isAuthenticated = async function () {
    const token = await getToken();

    return !!(token && token !== '' && token?.length > 100);
  };

  window.TV2 = {
    ...window.TV2,
    login,
    getLoggedInUser,
    getToken,
    setToken,
    isAuthenticated,
  };

  const init = async function () {
    const user = await getLoggedInUser();
    const userId = user?.id;

    // Deprecated - still in use until all uses are updated to new event
    document.dispatchEvent(
      new CustomEvent('tv2-menu:auth0-ready', {
        detail: {
          app: true,
          isReady: true,
          userId,
        } as AuthReadyEventDetail,
      }),
    );

    // New event to take over for above event
    document.dispatchEvent(
      new CustomEvent('tv2-menu:auth-ready', {
        detail: {
          app: true,
          isReady: true,
          userId,
        } as AuthReadyEventDetail,
      }),
    );
  };

  init();
};
