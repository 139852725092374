import type { Auth0Client, LoginOpts } from './types/auth0Client';
import { trackUserLoginEvent } from './reporting';

export const setupClient = (): Promise<Auth0Client> => {
  const { callbackUrl, domain, clientId, userApiUrl } = window.AUTH0_CONFIG;

  if (!window.auth0) return Promise.reject('auth0 script not loaded');

  return window.auth0
    .createAuth0Client({
      domain,
      clientId,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      authorizationParams: {
        audience: `https://${domain}`,
        redirect_uri: `${callbackUrl}?internalUrl=${encodeURIComponent(location.href.toString())}`,
      },
    })
    .then(auth0Client => {
      const login = async function (reasonToLoginCategory?: string, reasonToLoginName?: string) {
        const opts: LoginOpts = {
          authorizationParams: {},
        };

        if (reasonToLoginCategory) {
          opts.authorizationParams.reasonToLoginCategory = reasonToLoginCategory;
        }

        if (reasonToLoginName) {
          opts.authorizationParams.reasonToLoginName = reasonToLoginName;
        }

        await auth0Client.loginWithRedirect(opts);
      };

      const logout = async function () {
        const user = await window?.TV2?.getLoggedInUser();
        const userId = user?.id;

        trackUserLoginEvent('Logout Clicked', userId);

        await auth0Client.logout({
          logoutParams: {
            returnTo: `${callbackUrl}?internalUrl=${encodeURIComponent(location.href.toString())}&logout=true`,
          },
        });
      };

      const isAuthenticated = async function () {
        return await auth0Client.isAuthenticated();
      };

      const getLoggedInUser = async function () {
        try {
          const token = await auth0Client.getTokenSilently({ authorizationParams: { redirect_uri: location.origin } });

          const userResponse = await fetch(userApiUrl, {
            method: 'get',
            credentials: 'include',
            headers: new Headers({
              Authorization: 'Bearer ' + token,
            }),
          });

          return await userResponse.json();
        } catch (e) {
          return;
        }
      };

      const getToken = async function () {
        return await auth0Client.getTokenSilently({ authorizationParams: { redirect_uri: location.origin } });
      };

      window.TV2 = { ...window.TV2, login, logout, isAuthenticated, getLoggedInUser, getToken };

      return auth0Client;
    });
};
