const RECENT_LOGIN_CHECK_COOKIE = 'tv2HasCheckedLogin'

export const setRecentlyCheckedLoginCookie = () => {
    document.cookie = `${RECENT_LOGIN_CHECK_COOKIE}=1; domain=${window.location.hostname}; path=/; max-age=${60 * 30}`
}

export const hasRecentlyCheckedLogin = () => document.cookie
    .split(';')
    .some((item) =>
        item.trim().startsWith(`${RECENT_LOGIN_CHECK_COOKIE}=`)
    )

export const getCookie = (name: string) => {
  const cookies = document.cookie.split(';');

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split('=');
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
};