import {MENU_ELEMENT_ID, MENU_MEGAMENU_TOGGLE_ELEMENT_ID} from "../common/constants";

export const handleMegamenu = () => {
    const $menu = document.getElementById(MENU_ELEMENT_ID);
    const $menuToggle = document.getElementById(MENU_MEGAMENU_TOGGLE_ELEMENT_ID);
    const $menuCloseButton: HTMLAnchorElement | null = document.querySelector('.tv2-menu__megamenu__close-button');
    const $html = document.querySelector('html');
    const initialHtmlOverflow = $html?.style.overflow;

    window.TV2 = {
        ...window.TV2,
        toggleNavigation: function () {
            $html?.style.setProperty('overflow', $html?.style.overflow === 'hidden' ? initialHtmlOverflow ?? '' : 'hidden');
            $menuToggle?.classList.toggle('tv2-menu__megamenu__toggle--open');
            $menu?.classList.toggle('tv2-menu--open');
            $menuToggle?.focus();
        }
    };

    if ($menuToggle) {
        $menuToggle.onclick = window.TV2.toggleNavigation;
    }

    if ($menuCloseButton) {
        $menuCloseButton.onclick = () => {
            window.TV2.toggleNavigation();
        };
    }

    document.addEventListener('keyup', (event) => {
        if (event.key === 'Escape' && $menu?.classList.contains('tv2-menu--open')) {
            window.TV2.toggleNavigation();
        }
    });
}
