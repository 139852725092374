import {Auth0Client} from "./types/auth0Client";

export const handleAuthCallback = (auth0Client: Auth0Client): Promise<Auth0Client> => {
    const url = new URL(location.href);
    const searchParams = new URL(location.href).searchParams;
    const isRedirectCallback = searchParams.has('code') && searchParams.has('state');

    return isRedirectCallback
        ? auth0Client.handleRedirectCallback()
            .then(() => {
                url.searchParams.delete('code');
                url.searchParams.delete('state');
                history.replaceState({}, document.title, url);
                return auth0Client
            })
        : Promise.resolve(auth0Client);
}
