/*
 * Check if the user is authenticated with Auth0
 */

const MAX_RETRIES = 10;
const TIME_BETWEEN_RETRIES_MS = 100;

export const onCheckAuth0 = (): Promise<boolean> => {
  let retries = 0;

  const retry = (): Promise<boolean> =>
    typeof window.auth0 !== 'object' && retries < MAX_RETRIES
      ? new Promise(resolve => setTimeout(resolve, TIME_BETWEEN_RETRIES_MS)).then(() => {
          retries++;
          return retry();
        })
      : Promise.resolve(true);

  return retry();
};
