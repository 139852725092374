import {MENU_ELEMENT_CLASS_MINIMIZED, MENU_ELEMENT_ID, MENU_TOP_NAV_ELEMENT_ID} from "../common/constants";

type ScrollDirection = 'UP' | 'DOWN'

export const handleScroll = () => {
    const $menu = document.getElementById(MENU_ELEMENT_ID);
    const $topNav = document.getElementById(MENU_TOP_NAV_ELEMENT_ID);
    const menuHeight = Math.ceil($menu?.getBoundingClientRect().height ?? 0);
    const topNavHeight = Math.ceil($topNav?.getBoundingClientRect().height ?? 0);
    const menuExpandThresholdPx = 30;
    let hidden = false;
    let lastScrollTop: number = window.scrollY;

    document.addEventListener("scroll", () => {
        const scrollTop = window.scrollY;
        const scrollDelta = Math.abs(scrollTop - lastScrollTop);
        const scrollDirection: ScrollDirection = lastScrollTop < scrollTop ? 'DOWN' : 'UP';

        if (scrollDirection === 'DOWN') {
            if (scrollTop > menuHeight) {
                window.requestAnimationFrame(() => {
                    if (!hidden) {
                        hidden = true;
                        $menu?.classList.add(MENU_ELEMENT_CLASS_MINIMIZED);
                        $menu?.style.setProperty('transform', 'translateY(' + -topNavHeight + 'px)');
                        const downEvent = new CustomEvent('tv2-menu:scroll-down', {});
                        document.dispatchEvent(downEvent);
                    }
                });
            }
        }

        if (scrollDirection === 'UP') {
            if (scrollDelta > menuExpandThresholdPx || scrollTop < menuHeight) {
                window.requestAnimationFrame(() => {
                    if (hidden) {
                        hidden = false;
                        $menu?.classList.remove(MENU_ELEMENT_CLASS_MINIMIZED);
                        $menu?.style.setProperty('transform', 'translateY(0)');
                        const upEvent = new CustomEvent('tv2-menu:scroll-up', {});
                        document.dispatchEvent(upEvent);
                    }
                });
            }
        }

        lastScrollTop = scrollTop;
    });
}
