import {MENU_ELEMENT_CLASS_MOBILE_CATEGORIES_HIDDEN, MENU_ELEMENT_ID} from "../common/constants";

export const showMobileCategories = () => {
    const $menu = document.getElementById(MENU_ELEMENT_ID);
    $menu?.classList.remove(MENU_ELEMENT_CLASS_MOBILE_CATEGORIES_HIDDEN);
    updateBodyPaddingTop($menu);
};

export const hideMobileCategories = () => {
    const $menu = document.getElementById(MENU_ELEMENT_ID);
    $menu?.classList.add(MENU_ELEMENT_CLASS_MOBILE_CATEGORIES_HIDDEN);
    updateBodyPaddingTop($menu);
};

const updateBodyPaddingTop = ($menu: HTMLElement | null) => {
    const menuHeight = Math.round($menu?.getBoundingClientRect().height ?? 0);
    document.body.style.paddingTop = menuHeight + 'px'
}
