import type { TrackingEvent } from '../global';

const track = (event: TrackingEvent) => {
  window.snowplowTracker?.coreTracker?.trackUserLogin({
    userId: event.userID,
    userAction: event.eventAction,
  });
};

export const trackUserLoginEvent = (action: string, userId: string = '') => {
  track({
    event: 'loginEvent',
    eventCategory: 'Login',
    eventAction: action,
    userID: userId,
  });
};
