/*
 * Some sites that include the menu may use a fouc-prevention technique that hides body content while
 * all resources are loading. In these cases the menu height will be 0 until this content is displayed.
 * We therefore add this onClientHeight function to wait for the menu to have a client height before initialising
 * scroll logic and adding top padding to the body. We limit this sequence to 10 retries (1s).
 */

const MAX_RETRIES = 10;
const TIME_BETWEEN_RETRIES_MS = 100;

export const onClientHeight = ($el: HTMLElement | null) => {
    let retries = 0;
    const retry = (): Promise<number> => {
        const menuHeight = Math.round($el?.getBoundingClientRect().height ?? 0);
        if (menuHeight === 0 && retries < MAX_RETRIES) {
            retries++;
            return new Promise(resolve => setTimeout(resolve, TIME_BETWEEN_RETRIES_MS)).then(retry)
        }
        return Promise.resolve(menuHeight)
    }
    return retry();
}
