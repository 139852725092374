const localstorage = window?.localStorage || null;

export const getLocalStorageItem = (key: string) => {
  const itemString = localstorage?.getItem(key);

  if (!itemString) {
    return null;
  }

  const item = JSON.parse(itemString);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localstorage?.removeItem(key);
    return null;
  }

  return item.value;
};

export let setLocalStorageItem = (key: string, value: string | object, ttl = 2) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl * 60 * 60 * 1000,
  };

  localstorage?.setItem(key, JSON.stringify(item));
};

export let removeLocalStorageItem = (key: string) => {
  localstorage?.removeItem(key);
};